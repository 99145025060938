<template>
  <a-layout>
    <a-layout-sider
      :style="
        swap
          ? 'width: 60px !important; max-width: 60px !important; min-width: 60px !important; '
          : 'width: 110px !important; max-width: 110px !important; min-width: 110px !important; '
      "
    >
      <Menu :class="swap ? 'swap' : ''" />
    </a-layout-sider>
    <a-layout-content
      :style="
        swap
          ? 'position: fixed; left: 80px; width: calc(100% - 6.25rem); height:calc(100vh - 2rem)'
          : 'position: fixed; left: 120px; width: calc(100% - 8.125rem) ; height:calc(100vh - 2rem)'
      "
    >
      <a-layout-header v-if="$route.meta.title != 'Home'">
        <Header />
      </a-layout-header>
      <div>
        <a-button type="burger" @click="swiftbutton"
          ><a-icon :type="swap ? 'right' : 'left'" />
        </a-button>
      </div>
      <div style="width: 100%; margin: 0 auto">
        <router-view>
          <template slot="breadcrumb">
            <div>
              <h1>{{ $route.meta.title }}</h1>
            </div>
          </template>
          <template slot="onlybreadcrumb"></template>
        </router-view>
        <ChangePass ref="password" />
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
import Vue from "vue";
import Header from "./header.vue";
import Menu from "./sidebar.vue";
import ChangePass from "@/components/Modal/changePassword.vue";
//import { mapGetters, mapState } from "vuex";
//import SideBar from "./sidebar.vue";

import moment from "moment";

export default {
  components: {
    //SideBar,
    Menu,
    Header,
    ChangePass,
  },
  data() {
    moment.updateLocale(moment.locale("en"), null);
    return {
      swap: false,
    };
  },

  computed: {
    userID() {
      return this.$store.state.Auth.data.user.id;
    },
    changpswd() {
      return this.$store.state.Auth.data.user.first_time_reset;
    },
  },

  // watch:{
  //     $route (to, from){
  //         this.show = false;
  //     }
  // } ,

  mounted() {
    this.ChangePass();
  },
  methods: {
    ChangePass() {
      if (this.changpswd == null) {
        this.$refs.password.Show(this.userID);
      }
    },
    breadcrumb() {
      const path = this.$route.path.split("/");

      return path.filter((key) => key != "");
    },
    swiftbutton() {
      this.swap = !this.swap;
    },
  },
};
</script>
