<template>
  <div class="wrapsidemenu">
    <ul class="sidebarmenu">
      <li>
        <router-link
          :to="{ name: 'Home' }"
          :class="
            $route.meta.title == 'Home' ||
            $route.meta.title == 'Detail Profile' ||
            $route.meta.title == 'Klaim' ||
            $route.meta.title == 'Movement'
              ? 'active'
              : ''
          "
        >
          <span>Home</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'ListKlaim' }"
          :class="
            $route.meta.title == 'Rekap Klaim' ||
            $route.meta.title == 'Detail Klaim'
              ? 'active'
              : ''
          "
        >
          <span>Rekap Klaim</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'ListDaftarGroup' }"
          :class="
            $route.meta.title == 'Daftar Group' ||
            $route.meta.title == 'Daftar PT' ||
            $route.meta.title == 'Data Peserta' ||
            $route.meta.title == 'Benefit' ||
            $route.meta.title == 'Ketentuan Umum' ||
            $route.meta.title == 'Detail Peserta' ||
            $route.meta.title == 'Tambah Peserta'
              ? 'active'
              : ''
          "
        >
          <span>Daftar Group</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'ListQueAntrianData' }"
          :class="
            $route.meta.title == 'Antrian Data' ||
            $route.meta.title == 'Upload Failed'
              ? 'active'
              : ''
          "
        >
          <span>Antrian Data</span>
        </router-link>
      </li>

      <li v-if="permission_temporary !== undefined && permission_temporary > 0">
        <router-link
          :to="{ name: 'ListReport' }"
          :class="$route.meta.title == 'Report' ? 'active' : ''"
        >
          <span>Report</span>
        </router-link>
      </li>
    </ul>

    <div class="logoutmenu">
      <router-link to="#" @click.native="Logout">
        <img :src="require('@/assets/img/menu/Keluar.svg')" />
        <span>Keluar</span>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    permission_temporary() {
      return this.$store.state.Auth.data.counter;
    },
  },
  methods: {
    Logout() {
      this.$store.dispatch("Logout").then((response) => {
        console.log("response", response);
        this.$notification.success({
          message: response.data.message,
        });
      });
    },
  },
};
</script>
