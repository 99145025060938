<template>
  <div>
    <div>
      <a-dropdown :trigger="['click']" placement="bottomRight"
        ><div id="components-badge-demo-dot">
          <a @click="ShowNotif">
            <a-badge :count="count" :offset="[1, 3]"
              ><a-icon type="bell" theme="filled"
            /></a-badge>
          </a>
        </div>
        <div slot="overlay" class="dropdowncomponent" style="overflow: hidden">
          <h2>{{ count }} Pemberitahuan</h2>

          <a-button type="link"
            ><a-icon type="close-circle" theme="filled"
          /></a-button>
          <simplebar
            class="simplebarcustom"
            data-simplebar-auto-hide="false"
            style="min-height: 150px"
          >
            <div style="min-height: 200px">
              <ul v-if="data.length > 0">
                <li v-for="(item, i) in data" :key="i">
                  <router-link
                    to="#"
                    @click.native="RedirectMovement(item.is_read, item.id)"
                    v-if="item.kategori != 'Claim'"
                  >
                    <span>
                      <img
                        :src="require('@/assets/img/menu/Antrian.svg')"
                        width="40px"
                      />
                      {{ item.batch_number }} - {{ item.nama_group }}</span
                    >

                    <span style="padding-top: 10px">
                      <a-tooltip placement="top" :title="item.status">
                        <a-button type="notifikasi">
                          <div class="statuswrapnotif">
                            <img
                              :src="require('@/assets/img/icon/reject-dm.svg')"
                              v-if="item.status == 'Dibatalkan'"
                            />
                            <img
                              :src="
                                require('@/assets/img/icon/on-proses-dm.svg')
                              "
                              v-if="item.status == 'Sedang Dalam Proses'"
                            />
                            <img
                              :src="
                                require('@/assets/img/icon/verification-dm.svg')
                              "
                              v-if="item.status == 'Menunggu Verifikasi'"
                            />
                            <img
                              :src="require('@/assets/img/icon/approve-dm.svg')"
                              v-if="item.status == 'Approved'"
                            />
                            <img
                              :src="require('@/assets/img/icon/hold-dm.svg')"
                              v-if="item.status == 'Hold'"
                            />
                            {{ item.status }}
                          </div>
                        </a-button></a-tooltip
                      ></span
                    >
                  </router-link>

                  <router-link
                    to="#"
                    @click.native="
                      Redirect(item.id_claim, item.is_read, item.id)
                    "
                    v-else
                  >
                    <span
                      ><img
                        :src="require('@/assets/img/menu/Klaim.svg')"
                        width="40px"
                      />
                      {{ item.nama_peserta }}</span
                    >

                    <span style="padding-top: 10px">
                      <a-tooltip placement="top" :title="item.status">
                        <a-button type="notifikasi">
                          <div class="statuswrapnotif">
                            <img
                              :src="require('@/assets/img/icon/mv.svg')"
                              v-if="item.status == 'Menunggu Verifikasi'"
                            />
                            <img
                              :src="require('@/assets/img/icon/pb.svg')"
                              v-if="item.status == 'Proses Banding ke Asuransi'"
                            />
                            <img
                              :src="require('@/assets/img/icon/mlk.svg')"
                              v-if="
                                item.status ==
                                'Mohon Lengkapi Kekurangan Dokumen'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/pdg.svg')"
                              v-if="item.status == 'Pending'"
                            />
                            <img
                              :src="require('@/assets/img/icon/sdoa.svg')"
                              v-if="
                                item.status == 'Sedang di-review oleh Asuransi'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/ddp.svg')"
                              v-if="item.status == 'Disetujui Dibayarkan Penuh'"
                            />
                            <img
                              :src="require('@/assets/img/icon/dds.svg')"
                              v-if="
                                item.status == 'Disetujui Dibayarkan Sebagian'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/td.svg')"
                              v-if="item.status == 'Tidak Dicover'"
                            />
                            <!-- <img :src="require('@/assets/img/icon/reupload.svg')" /> -->
                            {{ item.status }}
                          </div>
                        </a-button></a-tooltip
                      ></span
                    >
                  </router-link>
                </li>
                <!-- <li>
              <router-link to="#">
                <span> Midplaaz Prima & Group</span>

                <span>
                  <a-button type="notifikasi">
                    Reupload
                    <img
                      :src="require('@/assets/img/icon/reupload.svg')"
                    /> </a-button
                ></span>
              </router-link>
            </li>
            <li>
              <router-link to="#">
                <span>Aminah Sarah</span>
                <span>
                  <a-button type="diajukan">
                    <img :src="require('@/assets/img/icon/hands.svg')" />
                    Diajukan
                  </a-button></span
                >
              </router-link>
            </li>
            <li>
              <router-link to="#">
                <span>Bella Hadid</span>
                <span>
                  <a-button type="verifikasi">
                    <img :src="require('@/assets/img/icon/hourglass.svg')" />
                    Verifikasi
                  </a-button></span
                >
              </router-link>
            </li> -->
              </ul>

              <a-empty v-else>
                <div slot="description">0 Notification</div>
              </a-empty>
            </div>
          </simplebar>
        </div>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
export default {
  components: {
    simplebar,
  },
  data() {
    return {
      loading: false,
      data: [],
      page: 1,
      count: 0,
      pageSizes: 15,
    };
  },
  computed: {
    id() {
      return this.$store.state.Auth.data.user.id;
    },
  },
  sockets: {
    connect: function () {
      console.log("socket nyambung");
    },
    disconnect() {
      console.log("dc");
    },
    EVENT: function (val) {
      if (val.type == "GET-NOTIF") {
        if (val.user == this.id) {
          this.count += 1;
        }
      }
      if (val.type == "READ-NOTIF") {
        if (val.user == this.id) {
          this.count -= 1;
        }
      }
    },
  },
  mounted() {
    document.body.classList.remove("ModalCustom");
    this.fetch({
      page: this.page,
      pageSizes: this.pageSizes,
    });
  },
  methods: {
    RedirectMovement(read, id_notif) {
      if (read == null) {
        this.$store
          .dispatch("ReadNotif", {
            id: id_notif,
          })
          .then((response) => {
            this.$router.push({ name: "ListQueAntrianData" });
          });
      } else {
        this.$router.push({ name: "ListQueAntrianData" });
      }
    },
    Redirect(ids, read, id_notif) {
      if (read == null) {
        this.$store
          .dispatch("ReadNotif", {
            id: id_notif,
          })
          .then((response) => {
            this.$router.push({ name: "DetailKlaim", params: { id: ids } });
          });
      } else {
        this.$router.push({ name: "DetailKlaim", params: { id: ids } });
      }
    },
    ShowNotif() {
      if (this.count != this.data.length) {
        this.fetch({
          page: this.page,
          pageSizes: this.pageSizes,
        });
      }
    },
    fetch(params = {}) {
      this.loading = true;
      this.$store
        .dispatch("GetNotification", {
          ...params,
        })
        .then((response) => {
          this.loading = false;
          this.data = response.data.data;
          this.count = response.data.total;
          //console.log(this.data);
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
