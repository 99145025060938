<template>
  <div>
    <a-modal
      :title="
        change == false
          ? 'Please Change your Password'
          : 'Password Reset Success!'
      "
      v-model="modals"
      :centered="true"
      width="600px"
      :footer="null"
      :closable="false"
      :maskClosable="false"
      :keyboard="false"
    >
      <a-form :form="form" @submit="submit" v-if="change == false">
        <a-form-item label="Password">
          <a-input-password
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Silahkan isi password baru!',
                  },
                  {
                    min: 4,
                    message: 'Karakter password harus lebih dari 4',
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              },
            ]"
            @blur="handleConfirmBlur"
            placeholder="Password Baru"
          />
        </a-form-item>

        <a-form-item label="Konfirmasi Password">
          <a-input-password
            v-decorator="[
              'confirm_password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Silahkan konfirmasi password baru',
                  },
                  { validator: compareToFirstPassword },
                ],
              },
            ]"
            @blur="handleConfirmBlur"
            placeholder="Konfrimasi Password Baru"
          />
        </a-form-item>

        <a-form-item :wrapper-col="{ lg: 4, offset: 20 }">
          <a-button type="primary" html-type="submit" :loading="loading">
            Reset
          </a-button>
        </a-form-item>
      </a-form>

      <div v-else>
        <div style="margin-bottom: 20px">
          Your password has been change. You will be redirected to Login Page
          Automatically
          <div style="text-align: center">
            <h1 style="font-size: 50px">{{ countDown }}</h1>
          </div>

          <div style="margin-top: 10px; display: flex; justify-content: right">
            <a-button type="primary" @click="Oklah">OK</a-button>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "EditPassword" });
  },

  data() {
    return {
      modals: false,
      change: false,
      id: "",
      loading: false,
      countDown: 0,
      count_down_timer: null,
    };
  },
  mounted() {},
  watch: {
    countDown: {
      handler: function (action) {
        if (action == 0) {
          clearTimeout(this.count_down_timer);
          this.$store.dispatch("Unautorized");
        }
      },
      deep: true,
    },
  },
  methods: {
    Oklah() {
      this.$store.dispatch("Unautorized");
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.count_down_timer = setTimeout(() => {
          this.countDown--;
          this.countDownTimer();
        }, 1000);
      }
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },

    // sameToFirstPassword(rule, value, callback) {
    //   const form = this.form;
    //   if (value && value == form.getFieldValue("passwordlama")) {
    //     callback("password baru tidak bole sama dengan password lama!");
    //   } else {
    //     callback();
    //   }
    // },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback("Konfirmasi password tidak sesuai!");
      } else {
        callback();
      }
    },

    Show(id) {
      this.modals = true;
      this.id = id;
    },
    submit(e) {
      e.preventDefault();

      this.form.validateFields((err, value) => {
        if (!err) {
          this.loading = true;
          this.$store
            .dispatch("ResetPassword", {
              id: this.id,
              ...value,
              first_time_reset: 1,
            })
            .then((response) => {
              this.loading = false;
              this.change = true;
              this.$notification.success({
                message: response.data.message,
              });

              this.countDown = 10;
              this.countDownTimer();
            });
        }
      });
    },
  },
};
</script>
