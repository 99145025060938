<template>
  <div class="headernav">
    <div>
      <img :src="require('@/assets/img/logo.png')" />
    </div>
    <div class="topheaderright">
      <div>
        <a-dropdown :trigger="['click']" placement="bottomRight">
          <!-- <router-link :to="{ name: 'ProfileUser' }">
          <a-avatar
            v-if="profile.jenis_kelamin == 'Pria'"
            style="background-color: #747171"
            :src="require('@/assets/img/avatar-p.png')"
          />
          <a-avatar
            v-else
            style="background-color: #747171"
            :src="require('@/assets/img/avatar-w.png')"
          />
          {{ profile.nama_lengkap }}
        </router-link> -->

          <div style="cursor: pointer">
            <a-avatar
              v-if="profile.jenis_kelamin == 'Pria'"
              style="background-color: #747171"
              :src="require('@/assets/img/avatar-p.png')"
            />
            <a-avatar
              v-else
              style="background-color: #747171"
              :src="require('@/assets/img/avatar-w.png')"
            />
            {{ profile.nama_lengkap.split(" ")[0] }}
            {{
              profile.nama_lengkap.split(" ")[1] !== undefined
                ? profile.nama_lengkap.split(" ")[1]
                : ""
            }}
          </div>
          <div slot="overlay" class="dropdowncomponent profiledropdown">
            <ul>
              <li>
                <router-link :to="{ name: 'ProfileUser' }">
                  <!-- <img
                    :src="require('@/assets/img/menu/profiles_menu.svg')"
                    style="width: 25px"
                  /> -->
                  Profile
                </router-link>
              </li>
              <li>
                <router-link to="#" @click.native="ChangePassword">
                  <!-- <img
                    :src="require('@/assets/img/menu/profiles_menu.svg')"
                    style="width: 25px"
                  /> -->
                  Change Password
                </router-link>
              </li>
              <li>
                <router-link to="#" @click.native="Logout">
                  <!-- <img
                    :src="require('@/assets/img/menu/logout_menu.svg')"
                    style="width: 30px"
                  /> -->
                  Keluar</router-link
                >
              </li>
            </ul>
          </div>
        </a-dropdown>
      </div>
      <div>
        <Notif />
        <ChangePassword ref="action" />
      </div>
    </div>
  </div>
</template>
<script>
import ChangePassword from "@/components/Modal/changePasswordUser.vue";
import Notif from "@/components/notifications/notif.vue";

export default {
  components: {
    Notif,
    ChangePassword,
  },
  computed: {
    profile() {
      return this.$store.state.Auth.data.user;
    },
  },

  methods: {
    ChangePassword() {
      this.$refs.action.Show(this.profile.id);
    },
    Logout() {
      this.$store.dispatch("Logout").then((response) => {
        console.log("response", response);
        this.$notification.success({
          message: response.data.message,
        });
      });
    },
  },
};
</script>
