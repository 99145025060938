var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapsidemenu"},[_c('ul',{staticClass:"sidebarmenu"},[_c('li',[_c('router-link',{class:_vm.$route.meta.title == 'Home' ||
          _vm.$route.meta.title == 'Detail Profile' ||
          _vm.$route.meta.title == 'Klaim' ||
          _vm.$route.meta.title == 'Movement'
            ? 'active'
            : '',attrs:{"to":{ name: 'Home' }}},[_c('span',[_vm._v("Home")])])],1),_c('li',[_c('router-link',{class:_vm.$route.meta.title == 'Rekap Klaim' ||
          _vm.$route.meta.title == 'Detail Klaim'
            ? 'active'
            : '',attrs:{"to":{ name: 'ListKlaim' }}},[_c('span',[_vm._v("Rekap Klaim")])])],1),_c('li',[_c('router-link',{class:_vm.$route.meta.title == 'Daftar Group' ||
          _vm.$route.meta.title == 'Daftar PT' ||
          _vm.$route.meta.title == 'Data Peserta' ||
          _vm.$route.meta.title == 'Benefit' ||
          _vm.$route.meta.title == 'Ketentuan Umum' ||
          _vm.$route.meta.title == 'Detail Peserta' ||
          _vm.$route.meta.title == 'Tambah Peserta'
            ? 'active'
            : '',attrs:{"to":{ name: 'ListDaftarGroup' }}},[_c('span',[_vm._v("Daftar Group")])])],1),_c('li',[_c('router-link',{class:_vm.$route.meta.title == 'Antrian Data' ||
          _vm.$route.meta.title == 'Upload Failed'
            ? 'active'
            : '',attrs:{"to":{ name: 'ListQueAntrianData' }}},[_c('span',[_vm._v("Antrian Data")])])],1),(_vm.permission_temporary !== undefined && _vm.permission_temporary > 0)?_c('li',[_c('router-link',{class:_vm.$route.meta.title == 'Report' ? 'active' : '',attrs:{"to":{ name: 'ListReport' }}},[_c('span',[_vm._v("Report")])])],1):_vm._e()]),_c('div',{staticClass:"logoutmenu"},[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.Logout.apply(null, arguments)}}},[_c('img',{attrs:{"src":require('@/assets/img/menu/Keluar.svg')}}),_c('span',[_vm._v("Keluar")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }