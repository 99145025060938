var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":_vm.change == false
        ? 'Please Change your Password'
        : 'Password Reset Success!',"centered":true,"width":"600px","footer":null,"closable":false,"maskClosable":false,"keyboard":false},model:{value:(_vm.modals),callback:function ($$v) {_vm.modals=$$v},expression:"modals"}},[(_vm.change == false)?_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.submit}},[_c('a-form-item',{attrs:{"label":"Password"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: 'Silahkan isi password baru!',
                },
                {
                  min: 4,
                  message: 'Karakter password harus lebih dari 4',
                },
                {
                  validator: _vm.compareToFirstPassword,
                },
              ],
            },
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Silahkan isi password baru!',\n                },\n                {\n                  min: 4,\n                  message: 'Karakter password harus lebih dari 4',\n                },\n                {\n                  validator: compareToFirstPassword,\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Password Baru"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',{attrs:{"label":"Konfirmasi Password"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'confirm_password',
            {
              rules: [
                {
                  required: true,
                  message: 'Silahkan konfirmasi password baru',
                },
                { validator: _vm.compareToFirstPassword },
              ],
            },
          ]),expression:"[\n            'confirm_password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Silahkan konfirmasi password baru',\n                },\n                { validator: compareToFirstPassword },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Konfrimasi Password Baru"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ lg: 4, offset: 20 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(" Reset ")])],1)],1):_c('div',[_c('div',{staticStyle:{"margin-bottom":"20px"}},[_vm._v(" Your password has been change. You will be redirected to Login Page Automatically "),_c('div',{staticStyle:{"text-align":"center"}},[_c('h1',{staticStyle:{"font-size":"50px"}},[_vm._v(_vm._s(_vm.countDown))])]),_c('div',{staticStyle:{"margin-top":"10px","display":"flex","justify-content":"right"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.Oklah}},[_vm._v("OK")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }