import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import { Spin } from "ant-design-vue";
// import Vidle from "v-idle";
import store from "./store";

import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
import NProgress from "vue-nprogress";
import "vue-cal/dist/vuecal.css";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import Permissions from "@/utils/permission.js";
import Global from "@/utils/global.js";
import Select2 from "v-select2-component";
import Loadings from "@/assets/img/lottie/loading.json";
//import VueGtag from "vue-gtag";

import LottieAnimation from "lottie-web-vue";

Vue.config.productionTip = false;
Vue.component("Select2", Select2);
Vue.use(CKEditor);
Vue.use(Antd);
Vue.use(NProgress);
Vue.mixin(Global);
Vue.mixin(Permissions);
Vue.use(LottieAnimation);

// Vue.use(VueGtag, {
//   config: { id: "G-R6TE3GBSZZ" },
// });

Spin.setDefaultIndicator({
  indicator: (h) => {
    return (
      <LottieAnimation
        ref="anim"
        animation-data={Loadings}
        loop={true}
        autoPlay={true}
        style="scale:2.5"
      />
    );
  },
});

const nprogress = new NProgress();

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: new SocketIO("https://andikaassistant.id:1984", {
      //connection: new SocketIO("http://192.168.80.214:3000", {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 500,
      maxReconnectionAttempts: Infinity,
      query: { api_key: "VCXCEuvhGcBDP7XhiJJUDvR1e1D3eiVjgZ9VRiaV" },
    }),

    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
    options: {}, //Optional options
  })
);

new Vue({
  nprogress,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
